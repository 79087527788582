import React, { FC } from 'react';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Icon from '@salesforce/design-system-react/components/icon';
import EventStatusBadge from '../../../Shared/EventStatusBadge';

interface Status {
  status: string;
  visualIndicator: string;
  checkRejectLineStatus: boolean;
}
export interface Props {
  children?: FC;
  item?: Status;
}

const TransferStatusCell: FC<Props> = ({
  item = {
    status: '',
    visualIndicator: '',
    checkRejectLineStatus: false,
  },
}) => {
  const { status, visualIndicator, checkRejectLineStatus } = item;
  return (
    <DataTableCell title={status || 'NEW'}>
      <div style={{ display: 'flex' }}>
        <EventStatusBadge
          status={
            status === 'Requested' && visualIndicator === 'Rejected' ? status : visualIndicator
          }
        />
        {((status === 'Requested' && visualIndicator === 'Rejected') || checkRejectLineStatus) && (
          <div style={{ marginLeft: '10px', marginTop: '-1px' }}>
            <Icon
              title="Rejected"
              category="utility"
              name="warning"
              size="x-small"
              className="slds-icon-text-error"
            />
          </div>
        )}
      </div>
    </DataTableCell>
  );
};

export default TransferStatusCell;
