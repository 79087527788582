/* eslint-disable */
import React, { FC, useEffect, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Button from '@salesforce/design-system-react/components/button';
import DetailsHeader from './DetailsHeader';
import InventoryReturnDetailCatalog from './InventoryReturnDetailCatalog';
import LineItems from './LineItems';
import Comments from './../InventoryRequestDetail/Comments';
import SUBMIT_REVIEW_RETURN from '../../../graphql/mutations/submitReviewReturn';
// import { getUTCDateTime } from '../../../../util/utilityFunctions';
import '../index.scss';
import { OPS_ACTION_TYPE, WORKFLOW_ACTION, NOTIFY_TYPE } from '../../../util/constants';
import { getUTCDateTime } from '../../../util/utilityFunctions';
import SnackBar from '../../Shared/SnackBar';
import FilterTagsForModal from '../../Shared/FilterTagsForModal';
import { GET_INVENTORY_RETURN_DETAILS } from '../../../graphql/getInventoryReturnDetails';
import { GET_FORM_CONFIGURATIONS } from '../../../graphql/getFormConfigurations';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import InventoryReturn from '../InventoryReturns/InventoryReturn';
import InventoryReturnAddItems from '../InventoryReturnDetail/InventoryReturnAddItems';
import CancelEventModal from '../../CreateEditSurgeryEvent/CancelEventCreationModal';

interface InventoryDetails {
  salesRep: string;
  eventStatus: string;
}

interface LineItems {
  productId: string;
  quantity: string;
  productNumber: string;
  workflowAction?: string;
  partAddedFrom?: string;
  sourceLocation?: string;
  usageId?: string;
  usageExternalId?: string;
}

interface DynamicValue {
  [key: string]: any;
}

interface SelectedData {
  id: string;
  value: string;
  __typename?: string;
}

const InventoryReturnApproval: FC<RouteComponentProps> = ({ location, history }) => {
  const { state } = location;
  const [loading, setLoader] = useState(false);
  const { open, notification, openSnackBar } = useSnackBar();
  const [
    getInventoryReturnDetails,
    { data, refetch: refetchInventoryReturnDetails, loading:inventoryLoading },
  ] = useLazyQuery(GET_INVENTORY_RETURN_DETAILS, {
    fetchPolicy: 'no-cache',
  });
  const [inventoryReturnDetails, setInventoryReturnDetails] = useState<any>();
  const [reviewInventoryReturn] = useMutation(SUBMIT_REVIEW_RETURN);
  const [shipMethod, setShipMethod] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [canceModalVisible, setCanceModalVisible] = useState(false);
  const [isAddParts, setIsAddParts] = useState(false);
  const [lineItems, setLineItems] = useState<LineItems[]>();
  const [userComment, setUserComment] = useState('');
  const [viewOnly, setViewOnly] = useState(false);
  const [getFormConfigurations, { data: formdata }] = useLazyQuery(GET_FORM_CONFIGURATIONS);
  const [selectedFilter, setSelectedFilter] = useState<DynamicValue | null>(null);
  const [selectedFilterApiValues, setSelectedFilterApiValues] = useState<DynamicValue | null>(null);
  const [selectedTabIndex, changeSelectedTab] = useState(0);

  useEffect(() => {
    getFormConfigurations();
  }, [getFormConfigurations]);

  useEffect(() => {
    if (state) {
      const { externalId, opsAction } = state;
      if (externalId) {
        if (opsAction && opsAction === OPS_ACTION_TYPE.ASSIGN) {
          setViewOnly(true);
        }
        getInventoryReturnDetails({
          variables: {
            id: externalId,
          },
        });
      } else {
        // No external id -- navigate to list
        history.push('./Inventory/Review');
      }
    }
  }, [getInventoryReturnDetails, history, state]);
  useEffect(() => {
    if (data && data.getInventoryReturnDetails) {
      setInventoryReturnDetails(data.getInventoryReturnDetails);
      setShipMethod(data.getInventoryReturnDetails.shipMethod);
      setLoader(false);
    }
  }, [data]);

  const handleModalToggle = useCallback((): void => {
    setIsEdit(!isEdit);
  }, [isEdit]);

  const handleEditClick = () => {
    setIsEdit(true);
  };

  const addLineItems = () => {
    setIsAddParts(true);
  };

  const saveLineItems = (items: any) => {
    const { getInventoryReturnDetails: details } = data;
    const parts = items || details.lineItems;
    const lineItemsPayload = parts.map((item: any) => {
      const {
        productId,
        quantity,
        partAddedFrom,
        sourceLocation,
        productNumber,
        workflowAction,
        userworkflowAction,
        // usageId,
        // usageExternalId,
        id,
        externalId,
        lotNumber,
        counterValue,
        svmxcTracking,
      } = item;
      const action = userworkflowAction
        ? userworkflowAction[0].value
        : workflowAction || WORKFLOW_ACTION.NO_ACTION;

      return {
        id,
        externalId,
        productId,
        quantity: counterValue ? counterValue.toString() : quantity.toString(),
        productNumber,
        partAddedFrom: sourceLocation,

        // usageId,
        // usageExternalId,
        lotNumber: lotNumber || '',
        type: 'Transfer to Warehouse',
        svmxcTracking: svmxcTracking? svmxcTracking: '',
        workflowAction: action,
      };
    });

    let commentsPayload;
    if (userComment) {
      commentsPayload = {
        comment: userComment,
        postTime: getUTCDateTime(new Date()),
        commentType: 'Internal',
      };
    }
    const payload = {
      orderId: details.orderId,
      externalId: details.externalId,
      accountId: details.accountId,
      salesRepId: details.salesRepId,
      fromSubInventoryCode: details.fromSubInventoryCode,
      salesOrganization: details?.salesOrganization || '',
      shipDate: details.shipmentDate,
      trackingId: details.trackingNumber,
      returnReason: details.returnReason,
      comments: commentsPayload,
      lineItems: lineItemsPayload,
      orderExternalId: details.orderExternalId,
    };
    setLoader(true);
    reviewInventoryReturn({
      variables: {
        ...payload,
      },
    })
      .then((res: any) => {
        setLoader(false);
        if (res && res.data && res.data.reviewInventoryReturn.message === 'success') {
          openSnackBar(NOTIFY_TYPE.SUCCESS, 'Inventory Returns details saved successfully');
          refetchInventoryReturnDetails();
        }
      })
      .catch(error => {
        if (error?.message?.toLowerCase().includes('permission denied')) {
          setLoader(false);
          openSnackBar(
            NOTIFY_TYPE.ERROR,
            'Submit is not allowed while user is on hold. Please contact Ops.'
          );
        } else {
          setLoader(false);
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
        }
      });
  };

  const handleCancelButton = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };

  const handleCancelModal = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };

  const handleCancelModalConfirmPress = (): void => {
    setCanceModalVisible(!canceModalVisible);
    setIsEdit(!isEdit);
  };

  const onHandleCloseSearchCatalogModal = (): void => {
    setIsAddParts(false);
    refetchInventoryReturnDetails();
  };

  const setLineItemsPayload = (items: any): void => {
    setLineItems(items);
  };
  const setShipMethodValue = (item: string): void => {
    setShipMethod(item);
  };
  const setUserEnteredComment = (comment: string): void => {
    setUserComment(comment);
  };

  const toCheckRedParts = () => {
    const checkLineParts = inventoryReturnDetails?.lineItems?.find(
      (part: any) =>
        !part?.isValidLotSerialCombination && part?.iscatalog && part?.lotValidation === 'true'
    );
    const checkRedPart =
      (inventoryReturnDetails?.type === 'Pickup Return' ||
        inventoryReturnDetails?.type === 'Inventory Return') &&
      inventoryReturnDetails?.status !== 'Completed' &&
      checkLineParts
        ? true
        : false;
    return checkRedPart;
  };

  const submitIRDetails = (): void => {
    const { getInventoryReturnDetails: details } = data;
    const parts = lineItems || details.lineItems;
    const redPartValidation = toCheckRedParts();
    let checkLotNotAvailable = false;
    parts.map((item: any): any => {
      if (
        ((!item?.lotNumber && item?.iscatalog) || (!item?.sourceLocation && item?.iscatalog)) &&
        item?.lotValidation === 'true'
      ) {
        checkLotNotAvailable = true;
      }
    });
    if (checkLotNotAvailable) {
      openSnackBar(NOTIFY_TYPE.ERROR, 'Lot # is not provided for one or more line item(s).');
      return;
    }
    if (redPartValidation) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'One or more parts are not available. Inventory needs to be adjusted before submitting.'
      );
      return;
    }
    // Validations
    // const actionableItems = parts.filter(
    //   (item: any) =>
    //     item.workflowAction === WORKFLOW_ACTION.NO_ACTION || item.workflowAction === 'NoAction'
    // );
    // if (actionableItems && actionableItems.length > 0) {
    //   const notAttempted = actionableItems.find((i: any) => !i.userworkflowAction);
    //   if (notAttempted) {
    //     openSnackBar(NOTIFY_TYPE.INFO, 'Please attempt all the line items');
    //     return;
    //   }
    //   const rejectedItemFound = actionableItems.find(
    //     (i: any) => i.userworkflowAction && i.userworkflowAction[0].value === WORKFLOW_ACTION.REJECT
    //   );
    //   if (rejectedItemFound && !userComment) {
    //     openSnackBar(NOTIFY_TYPE.INFO, 'Please provide comment for rejection');
    //     return;
    //   }
    // }
    // Proceed to Submit
    const lineItemsPayload = parts.map((item: any) => {
      const {
        productId,
        quantity,
        productNumber,
        workflowAction,
        userworkflowAction,
        sourceLocation,
        // usageId,
        // usageExternalId,
        id,
        externalId,
        lotNumber,
        counterValue,
        iscatalog,
        isLotNumber,
        svmxcTracking,
      } = item;
      const action = userworkflowAction
        ? userworkflowAction[0].value
        : workflowAction || WORKFLOW_ACTION.NO_ACTION;
      // if(!type){
      //   return [];
      // }else {
      // }
      return {
        id,
        externalId,
        productId,
        quantity: counterValue ? counterValue.toString() : quantity.toString(),
        productNumber,
        partAddedFrom: iscatalog ? 'Catalog' : sourceLocation,
        sourceLocation,
        // usageId,
        // usageExternalId,
        isLotNumber,
        lotNumber: lotNumber || '',
        type: 'Transfer to Warehouse',
        workflowAction: action,
        svmxcTracking: svmxcTracking? svmxcTracking: ''
      };
    });

    let commentsPayload;
    if (userComment?.length) {
      commentsPayload = {
        comment: userComment,
        postTime: getUTCDateTime(new Date()),
        commentType: 'Internal',
      };
    }
    const payload = {
      orderId: details.orderId,
      externalId: details.externalId,
      accountId: details.accountId,
      salesRepId: details.salesRepId,
      fromSubInventoryCode: details.fromSubInventoryCode,
      salesOrganization: details?.salesOrganization || '',
      shipDate: details.shipmentDate,
      trackingId: details.trackingNumber,
      returnReason: details.returnReason,
      comments: commentsPayload,
      lineItems: lineItemsPayload,
      orderExternalId: details.orderExternalId,
    };
    setLoader(true);
    reviewInventoryReturn({
      variables: {
        ...payload,
      },
    })
      .then((res: any) => {
        setLoader(false);
        if (res && res.data && res.data.reviewInventoryReturn.message === 'success') {
          openSnackBar(NOTIFY_TYPE.SUCCESS, 'Inventory details submitted successfully');
          // refetchInventoryReturnDetails();
          setTimeout(() => {
            history.push('Inventory/Review');
          }, 2500);
        }
      })
      .catch(error => {
        if (error?.message?.toLowerCase().includes('permission denied')) {
          setLoader(false);
          openSnackBar(
            NOTIFY_TYPE.ERROR,
            'Submit is not allowed while user is on hold. Please contact Ops.'
          );
        } else {
          setLoader(false);
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
        }
      });
  };

  const getFilterApiValue = (filterValues: DynamicValue): DynamicValue => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          const values = filterValues[key].map((item: SelectedData) => item.value);
          if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };

  const handleFilter = (filterValues: DynamicValue | null, filterName?: string): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setSelectedFilter(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      setSelectedFilterApiValues(filters);
    }
  };

  const visualIndicator = inventoryReturnDetails ? inventoryReturnDetails.visualIndicator : '';
  const { externalId } = state;
  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      {(loading || inventoryLoading) && <Spinner />}
      {inventoryReturnDetails && (
        <>
          <DetailsHeader
            inventoryReturnDetails={inventoryReturnDetails}
            setShipMethodValue={setShipMethodValue}
            handleEditClick={handleEditClick}
            submitIRDetails={submitIRDetails}
            viewOnly={viewOnly}
          />
          <InventoryReturnDetailCatalog
            inventoryReturnDetail={inventoryReturnDetails}
            refetch={refetchInventoryReturnDetails}
            changeSelectedTab={changeSelectedTab}
            visualIndicator={visualIndicator}
            handleLineItemActions={setLineItemsPayload}
            hasEventInventory
            type="pickup"
            externalId={externalId}
            isOrder={false}
            viewOnly={viewOnly}
          />
          {/* <LineItems
            inventoryReturnDetails={inventoryReturnDetails}
            handleLineItemActions={setLineItemsPayload}
            addLineItems={addLineItems}
            saveLineItems={saveLineItems}
            viewOnly={viewOnly}
          />*/}
          <Comments
            inventoryReturnDetails={inventoryReturnDetails}
            setUserEnteredComment={setUserEnteredComment}
            viewOnly={false}
            type=""
          /> 
          {isEdit && (
            <InventoryReturn
              isOpen={isEdit}
              toggleOpen={handleModalToggle}
              formData={formdata}
              inventoryReturnDetail={inventoryReturnDetails}
              refetchInventoryReturn={refetchInventoryReturnDetails}
              eventType="Return Inventory"
              type={'Review'}
              returnsType={
                inventoryReturnDetails &&
                inventoryReturnDetails.returnType &&
                inventoryReturnDetails.returnType === 'Inventory Return'
                  ? 'Standard Return'
                  : inventoryReturnDetails.returnType
              }
              handleCancelButton={handleCancelButton}
            />
          )}
          {selectedFilter && selectedFilter.filterValues && (
            <FilterTagsForModal selectedFilter={selectedFilter} handleFilter={handleFilter} />
          )}
          {isAddParts && (
            <InventoryReturnAddItems
              isOpen={isAddParts}
              handleClose={onHandleCloseSearchCatalogModal}
              inventoryReturnDetail={inventoryReturnDetails}
              selectedFilter={selectedFilter}
              handleFilter={handleFilter}
            />
          )}
          {canceModalVisible && (
            <CancelEventModal
              canceModalVisible={canceModalVisible}
              handleCancelModal={handleCancelModal}
              handleCancelModalConfirmPress={handleCancelModalConfirmPress}
              edit={isEdit}
            />
          )}
        </>
      )}
    </IconSettings>
  );
};

export default withRouter(InventoryReturnApproval);
