/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react';
import DataTable from '@salesforce/design-system-react/components/data-table';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import '../index.scss';
import TransferStatusCell from './TransferStatusCell';
import TransferIdCell from './TransferIdCell';
import TransferEventIdCell from './TransferEventIdCell';

interface Props {
  type: string;
  data: any[];
  sortOrderList: (list: any) => void;
  sortedcolumn?: (property: any) => void;
  getTransferList?: any;
  selectedFilter?: any;
  offsetData?: any;
}

interface Sort {
  sortColumn: string;
  sortColumnDirection: {
    [key: string]: string;
  };
}

// CustomDataTableCell must have the same displayName as DataTableCell or it will be ignored.
TransferIdCell.displayName = DataTableCell.displayName;
TransferEventIdCell.displayName = DataTableCell.displayName;
TransferStatusCell.displayName = DataTableCell.displayName;

const TransferDataTable: FC<Props> = ({
  data,
  type,
  sortedcolumn,
  getTransferList,
  selectedFilter,
}) => {
  const [sortObj, setSortObj] = useState<Sort>();
  const getFilterApiValue = (filterValues: any): any => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          const values = filterValues[key].map((item: any) => item.id);

          if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (Array.isArray(filterValues[key]) && filterValues[key]) {
          if (filterValues[key].length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };
  const handleSort = (sortColumn: any): void => {
    const sortProperty = sortColumn.property;
    const { sortDirection } = sortColumn;
    const sort = {
      sortColumn: sortProperty,
      sortColumnDirection: {
        [sortProperty]: sortDirection,
      },
    };
    if (sortedcolumn) {
      sortedcolumn(sortColumn);
    }
    getTransferList({
      variables: {
        limit: 50,
        offset: 0,
        type: type.toUpperCase(),
        orderBy: sortProperty,
        orderSortType: sortDirection,
        ...(selectedFilter && { filters: getFilterApiValue(selectedFilter.filterValues) }),
      },
    });

    setSortObj(sort);
  };
  // const handleSort = (sortColumn: any): void => {
  //   const sortProperty = sortColumn.property;
  //   const { sortDirection } = sortColumn;
  //   const sort = {
  //     sortColumn: sortProperty,
  //     sortColumnDirection: {
  //       [sortProperty]: sortDirection,
  //     },
  //   };
  //   // needs to work in both directions
  //   const list =
  //     data &&
  //     data.sort((a: any, b: any) => {
  //       // if (sortProperty === 'orderNumber') {
  //       //   // Number Compare
  //       //   if (sortDirection === 'desc') {
  //       //     return Number(b[sortProperty]) - Number(a[sortProperty]);
  //       //   }
  //       //   return Number(a[sortProperty]) - Number(b[sortProperty]);
  //       // }
  //       if (sortProperty === 'createdDate') {
  //         // Date Compare
  //         if (sortDirection === 'desc') {
  //           return Number(new Date(b[sortProperty])) - Number(new Date(a[sortProperty]));
  //         }
  //         return Number(new Date(a[sortProperty])) - Number(new Date(b[sortProperty]));
  //       }
  //       // String Compare
  //       let val = 0;
  //       if (a[sortProperty] === null) {
  //         if (b[sortProperty] < '') {
  //           val = -1;
  //         }
  //         if (b[sortProperty] > '') {
  //           val = 1;
  //         }
  //         if (sortDirection === 'desc') {
  //           val *= -1;
  //         }
  //       } else if (b[sortProperty] === null) {
  //         if (a[sortProperty] > '') {
  //           val = -1;
  //         }
  //         if (a[sortProperty] < '') {
  //           val = 1;
  //         }
  //         if (sortDirection === 'desc') {
  //           val *= -1;
  //         }
  //       } else {
  //         if (a[sortProperty] > b[sortProperty]) {
  //           val = 1;
  //         }
  //         if (a[sortProperty] < b[sortProperty]) {
  //           val = -1;
  //         }
  //         if (sortDirection === 'desc') {
  //           val *= -1;
  //         }
  //       }
  //       return val;
  //     });
  //   setSortObj(sort);
  //   sortOrderList(list);
  // };
  return (
    <DataTable
      assistiveText={{
        columnSort: 'sort this column',
        columnSortedAscending: 'asc',
        columnSortedDescending: 'desc',
      }}
      items={data || []}
      id="DataTableExample-2"
      className="font-12"
      fixedLayout
      keyboardNavigation
      joined
      onSort={handleSort}
    >
      {[
        <DataTableColumn
          key="orderNumber"
          label="Transfer"
          property="orderNumber"
          //   width="100px"
          {...(type !== 'Completed' && {
            isSorted: sortObj?.sortColumn === 'orderNumber',
            sortable: true,
            sortDirection: sortObj?.sortColumnDirection.orderNumber,
          })}
        >
          <TransferIdCell transferType={type} />
        </DataTableColumn>,
        <DataTableColumn
          key="eventId"
          label="Event"
          property="eventId"
          //   width="100px"
          {...(type !== 'Completed' && {
            isSorted: sortObj?.sortColumn === 'eventId',
            sortable: true,
            sortDirection: sortObj?.sortColumnDirection.eventId,
          })}
        >
          <TransferEventIdCell transferType={type} />
        </DataTableColumn>,
        <DataTableColumn
          key="type"
          label="Transfer Type"
          property="type"
          {...(type !== 'Completed' && {
            isSorted: sortObj?.sortColumn === 'type',
            sortable: true,
            sortDirection: sortObj?.sortColumnDirection.type,
          })}
        />,
        <DataTableColumn
          key="status"
          label="Status"
          property="status"
          {...(type !== 'Completed' && {
            isSorted: sortObj?.sortColumn === 'status',
            sortable: true,
            sortDirection: sortObj?.sortColumnDirection.status,
          })}
        >
          <TransferStatusCell />
        </DataTableColumn>,
        type === 'Failed' && (
          <DataTableColumn
            key="failureReason"
            label="Failure Reason"
            property="failureReason"
            isSorted={sortObj?.sortColumn === 'failureReason'}
            sortable
            sortDirection={sortObj?.sortColumnDirection.failureReason}
          />
        ),
        <DataTableColumn
          key="account"
          label="Receiving Account "
          property="account"
          {...(type !== 'Completed' && {
            isSorted: sortObj?.sortColumn === 'account',
            sortable: true,
            sortDirection: sortObj?.sortColumnDirection.account,
          })}
        />,
        <DataTableColumn
          key="salesRepName"
          label="Sales Rep"
          property="salesRepName"
          {...(type !== 'Completed' && {
            isSorted: sortObj?.sortColumn === 'salesRepName',
            sortable: true,
            sortDirection: sortObj?.sortColumnDirection.salesRepName,
          })}
        />,
        <DataTableColumn
          key="territoryNumber"
          label="Territory"
          property="territoryNumber"
          {...(type !== 'Completed' && {
            isSorted: sortObj?.sortColumn === 'territoryNumber',
            sortable: true,
            sortDirection: sortObj?.sortColumnDirection.territoryNumber,
          })}
        />,
        <DataTableColumn
          key="createdDate"
          label="Created Date"
          property="createdDate"
          {...(type !== 'Completed' && {
            isSorted: sortObj?.sortColumn === 'createdDate',
            sortable: true,
            sortDirection: sortObj?.sortColumnDirection.createdDate,
          })}
        />,
      ]}
    </DataTable>
  );
};

export default TransferDataTable;
