/* eslint-disable */
import React, { FC, useEffect, useState } from 'react';
import Card from '@salesforce/design-system-react/components/card';
import Icon from '@salesforce/design-system-react/components/icon';
import { Grid } from '@material-ui/core';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Button from '@salesforce/design-system-react/components/button';
import { useSelector } from 'react-redux';
import { useMutation, useLazyQuery, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import SnackBar from '../../Shared/SnackBar';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import '../index.scss';
import TextArea from '../../Shared/TextArea';
import { NOTIFY_TYPE, PERSONA_CONDITION } from '../../../util/constants';
import { getUserInfo } from '../../../store/ducks/userInfo';
import EDIT_COMMENT from '../../../graphql/mutations/editComment';
import Spinner from '@salesforce/design-system-react/components/spinner';
import DELETE_COMMENT from '../../../graphql/mutations/deleteComment';
import Modal from '@salesforce/design-system-react/components/modal';
import { GET_COMMENT_HISTORY } from '../../../graphql/getCommentHistory';
import { getUTCDateTime } from '../../../util/utilityFunctions';
import CREATE_COMMENT from '../../../graphql/mutations/createComment';

interface Props {
  inventoryReturnDetails: InventoryReturnDetails;
  setUserEnteredComment?: (comments: any) => void;
  viewOnly: boolean;
  type: string;
}

interface Comments {
  id?: string;
  comment: string;
  commentType?: string;
  postTime: string;
  userName?: string;
  userId?: any;
  commentExternalId?: any;
  updatedBy: string;
}

interface InventoryReturnDetails {
  eventStatus?: string;
  caseExternalId?: string;
  salesRep?: string;
  eventId?: string;
  accountNumber?: string;
  accountName?: string;
  subInventoryCode?: string;
  suibmitDate?: string;
  shippingAddessLine1?: string;
  shippingAddessLine2?: string;
  shippingPostalcode?: string;
  shippingCity?: string;
  shippingState?: string;
  serviceLevel?: string;
  submitDate?: string;
  comments: Comments[];
  caseId: string;
  externalId: string;
  orderExternalId: string;
}

const Comments: FC<Props> = ({
  inventoryReturnDetails,
  setUserEnteredComment,
  viewOnly,
  type,
}) => {
  const { open, notification, openSnackBar } = useSnackBar();
  // const { comments = [] } = inventoryReturnDetails;
  const [input, setInput] = useState('');
  const isManualIR =
    type && inventoryReturnDetails?.eventStatus && inventoryReturnDetails?.caseExternalId
      ? type === 'Open' &&
      inventoryReturnDetails?.eventStatus === 'Approved' &&
      inventoryReturnDetails?.caseExternalId
      : false;
  const [commentsList, setCommentList] = useState<Comments[]>();
  const userInfo = useSelector(getUserInfo);
  const [isEdit, setIsEdit] = useState('');
  const [deleteExternalId, setDeleteExternalId] = useState('');
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [editFieldText, setEditFieldText] = useState('');
  const [editComment, { data: updateCommentData, loading: updateCommentLoading }] = useMutation(
    EDIT_COMMENT
  );
  const [deleteComment, { loading: deleteCommentLoading }] = useMutation(DELETE_COMMENT);
  const [loader, setLoader] = useState(false);
  const {
    data: customerComments,
    refetch: refetchCommentHistory,
  } = useQuery(GET_COMMENT_HISTORY, {
    variables: {
      sfid: inventoryReturnDetails?.caseId,
      caseExternalId: inventoryReturnDetails?.caseExternalId || inventoryReturnDetails?.orderExternalId || inventoryReturnDetails?.externalId,
      commentType: 'Customer',
    },
  });
  const [createComment, { data: createCommentData }] = useMutation(CREATE_COMMENT);

  const setUserEnteredComments = (comment: any): void => {
    if (comment) {
      const obj = {
        comment,
        postTime: getUTCDateTime(new Date()),
        commentType: 'Customer',
        externalId: inventoryReturnDetails?.caseExternalId || inventoryReturnDetails?.orderExternalId || inventoryReturnDetails?.externalId
      };
      // setUserComment([...(userComment || []), obj]);
      createComment({
        variables: {
          comment,
          // eslint-disable-next-line no-nested-ternary
          caseExternalId: inventoryReturnDetails.caseExternalId  || inventoryReturnDetails?.orderExternalId || inventoryReturnDetails?.externalId,
          // (isEventInventoryFlow || hasEventInventory ? eventInventoryExternalId : externalId),
          postTime: getUTCDateTime(new Date()),
          commentType: 'Customer',
        },
      }).then(() => {
        refetchCommentHistory();
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        // eslint-disable-next-line no-console
        openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
        console.log(error); // TODO change when some operation needs to be run on error.
      });
    }
  };
  
  useEffect(() => {
    setCommentList(customerComments?.getCommentHistory);
    if (setUserEnteredComment) {
      setUserEnteredComment(customerComments?.getCommentHistory);
    }
  }, [customerComments, setCommentList, setUserEnteredComment]);
  const handleAdd = ():void => {
    setLoader(true);
    if (input) {
      setUserEnteredComments(input)
      setInput('');
    } else {
      openSnackBar(NOTIFY_TYPE.INFO, 'Please add your comment first');
      setLoader(false);
    }
  };

  const handleEdit = (commentData: any) => {
    if (editFieldText) {
      const obj = {
        externalId: isEdit,
        comment: editFieldText,
        postTime: getUTCDateTime(new Date()),
        // user: `${userInfo?.firstName} ${userInfo?.lastName}`,
      };
      editComment({
        variables: obj,
      }).then(()=>{
        refetchCommentHistory();
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
        console.log(error); // TODO change when some operation needs to be run on error.
      });
    } else {
      openSnackBar(NOTIFY_TYPE.INFO, 'Please add your comment first');
    }
    setIsEdit('');
  };
  const handleDelete = (externalId: any): void => {
    if (externalId) {
      const obj = {
        externalId: deleteExternalId,
      };
      deleteComment({
        variables: obj,
      })
        .then(() => {
          refetchCommentHistory();
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
          console.log(error); // TODO change when some operation needs to be run on error.
        });
    } else {
      openSnackBar(NOTIFY_TYPE.INFO, 'Seems like it is invalid comment.');
    }
    setDeleteConfirm(false);
  };

  const handleCancelModal = () => {
    setDeleteConfirm(false);
  };
  return (
    <IconSettings iconPath="/icons">
      {loader || updateCommentLoading || deleteCommentLoading ? (
        <Spinner size="large" variant="base" assistiveText={{ label: 'Loading...' }} />
      ) : (
        <div className="slds-m-around_small">
          <SnackBar open={open} notification={notification} />
          <Card
            id="Comments"
            heading={`Comments  ${
              commentsList && commentsList.length > 0 ? `(${commentsList.length})` : ``
            }`}
            icon={<Icon category="standard" name="feedback" size="small" />}
          >
            <hr className="card-divider" />
            <div className="review-comments-container">
              <div className="comments-container">
                {commentsList &&
                  commentsList.map((comment: Comments) => (
                    <div className="comment-body">
                      <p className="comment-user-name">
                        {comment.userName || ''}{' '}
                        {userInfo?.id === comment.userId && (
                          <>
                            <Button
                              assistiveText={{ icon: 'Edit Comment' }}
                              iconCategory="utility"
                              iconName="edit"
                              iconSize="small"
                              iconVariant="bare"
                              onClick={() => {
                                setIsEdit(comment.commentExternalId);
                                setEditFieldText(comment.comment);
                              }}
                              variant="icon"
                              disabled={viewOnly}
                            />
                            <Button
                              assistiveText={{ icon: 'Delete Comment' }}
                              iconCategory="utility"
                              iconName="delete"
                              iconSize="small"
                              iconVariant="bare"
                              onClick={() => {
                                setDeleteConfirm(true);
                                setDeleteExternalId(comment.commentExternalId);
                              }}
                              disabled={viewOnly}
                              variant="icon"
                            />
                          </>
                        )}
                      </p>
                      <h4 className="comment-timestamp">
                        {`${comment.updatedBy ? 'Edited - ' : ''} ${moment(comment.postTime).format('L')} at ${moment(
                          comment.postTime
                        ).format('LT')} `}
                      </h4>
                      {isEdit === comment?.commentExternalId ? (
                        <>
                          <TextArea
                            label=""
                            tooltipText="Edit Comment"
                            showTooltip={false}
                            disabled={false}
                            handleChangeText={(text: string): void => setEditFieldText(text)}
                            value={editFieldText}
                            maxLength="200"
                          />
                          <Grid container justifyContent="flex-end">
                            <Grid item>
                            <Button
                                align="right"
                                label="Cancel"
                                onClick={() => setIsEdit('')}
                              />
                              <Button
                                align="right"
                                label="Save"
                                className="yellow-btn"
                                onClick={() => handleEdit(comment)}
                                disabled={viewOnly || editFieldText === comment.comment}
                              />
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <p className="comments_text">{comment.comment}</p>
                      )}
                    </div>
                  ))}
              </div>
              <TextArea
                label="Add Comment"
                tooltipText="Add Comment"
                showTooltip={false}
                disabled={false}
                handleChangeText={(text: string): void => setInput(text)}
                value={input}
                maxLength="200"
              />
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button
                    align="right"
                    label="Add"
                    onClick={handleAdd}
                    // disabled={
                    //   type === 'Processed' ||
                    //   (type === 'Failed' &&
                    //     !(
                    //       userInfo &&
                    //       PERSONA_CONDITION.some(el =>
                    //         userInfo?.personas?.toLowerCase()?.includes(el)
                    //       )
                    //     ))
                    // }
                    disabled={viewOnly}
                  />
                </Grid>
              </Grid>
            </div>
          </Card>
        </div>
      )}
      {deleteConfirm && (
        <Modal
          className="cancel-creation-modal default-modal_header default-modal_footer"
          isOpen={deleteConfirm}
          onRequestClose={handleCancelModal}
          ariaHideApp={false}
          footer={[
            <Button label="Yes" onClick={handleDelete} key="yes" />,
            <Button label="No" variant="brand" onClick={handleCancelModal} key="no" />,
          ]}
          heading="Delete Confirmation"
        >
          <div className="slds-text-align--center">
            <div>
              <h1 className="slds-m-around--large ">Are you sure to delete this comment?</h1>
            </div>
          </div>
        </Modal>
      )}
    </IconSettings>
  );
};

export default Comments;
