/*
 * Event Details GraphQL specification
 * @module src/graphql/getInventoryRequestList
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/**  Inventory Request list GraphQL API query */
export const GET_INVENTORY_REQUEST_LIST = gql`
  query getInventoryRequestList(
    $limit: Int
    $offset: Int
    $type: String
    $orderBy: String
    $orderSortType: String
    $filters: InventoryRequestFilters
  ) {
    getInventoryRequestList(
      limit: $limit
      offset: $offset
      type: $type
      orderBy: $orderBy
      orderSortType: $orderSortType
      filters: $filters
    ) {
      caseId
      caseExternalId
      salesRepId
      salesRep
      eventType
      eventId
      division
      eventStatus
      serviceLevel
      needByDate
      parentCaseExternalId
      parentCaseId
      parentEventId
      parentEventType
      parentEventStatus
      parentCaseExternalId
      parentCaseId
      branchId
      branch
      accountId
      accountNumber
      accountName
      createdDate
      submittedDate
      shippingAddessLine1
      shippingAddessLine2
      shippingPostalcode
      shippingCity
      shippingState
      shippingId
      territoryNumber
      visualIndicator
      thresholdQuantityIndicator
      checkRejectLineStatus
    }
  }
`;
