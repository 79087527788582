/**
 * Graphql mutation to cancel event
 * @module src/graphql/cancelInventoryTransfer
 */
import gql from 'graphql-tag';

/** cancel event */
const CANCEL_INVENTORY_TRANSFER = gql`
  mutation cancelInventoryTransfer($externalId: String!, $cancelReason: String) {
    cancelInventoryTransfer(externalId: $externalId, cancelReason: $cancelReason) {
      message
    }
  }
`;

export default CANCEL_INVENTORY_TRANSFER;
